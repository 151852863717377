<div class="kingster-page-title-wrap kingster-style-medium kingster-left-align">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr">
      <div class="kingster-page-caption">NIRF</div>
      <h1 class="kingster-page-title">National Institute Ranking Framework</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class="kingster-content-container kingster-container">
    <div class="gdlr-core-page-builder-body">
      <div class="gdlr-core-pbf-wrapper">
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js">
          <div
            class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container"
          >
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-course-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-course-style-list"
                style="padding-bottom: 45px"
              >
                <app-file-viewer
                  src="https://storage.srnmcollege.ac.in/srnmcweb/nirf_report/nirf_2018.pdf"
                  title="NIRF 2018 "
                >
                </app-file-viewer>
                <app-file-viewer
                  src="https://storage.srnmcollege.ac.in/srnmcweb/nirf_report/nirf_2019.pdf"
                  title="NIRF 2019 "
                >
                </app-file-viewer>
                <app-file-viewer
                  src="https://storage.srnmcollege.ac.in/srnmcweb/nirf_report/nirf_2020.pdf"
                  title="NIRF 2020 "
                >
                </app-file-viewer>
                <app-file-viewer
                  src="https://storage.srnmcollege.ac.in/srnmcweb/nirf_report/NIRF_2021.pdf"
                  title="NIRF 2021 "
                >
                </app-file-viewer>
                <app-file-viewer
                  src="https://storage.srnmcollege.ac.in/srnmcweb/nirf_report/NIRF_2022.pdf"
                  title="NIRF 2022 "
                >
                </app-file-viewer>
                <app-file-viewer
                  src="https://storage.srnmcollege.ac.in/srnmcweb/nirf_report/nirf_2023.pdf"
                  title="NIRF 2023 "
                >
                </app-file-viewer>
                <app-file-viewer
                  src="https://storage.srnmcollege.ac.in/srnmcweb/nirf_report/nirf_2024.pdf"
                  title="NIRF 2024 "
                >
                </app-file-viewer>
                <app-file-viewer
                  src="https://storage.srnmcollege.ac.in/srnmcweb/nirf_report/NIRF_2025.pdf"
                  title="NIRF 2025 "
                >
                </app-file-viewer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
